import gql from 'graphql-tag';

// Mutations
export const SET_CONVERSION  = gql`
    mutation setConversion(
        $event_type: String!
        $event_family: String
        $payload: InputRDPayload!
        ) {
        	setConversion(
                conversion: {
                    event_type: $event_type
                    event_family: $event_family
                    payload: $payload
            }
        ) {
            event_uuid
        }
    }
`;