// import VueGtag from "vue-gtag";
import amplitude from 'amplitude-js';

export const saveAnalytics = (data) => {
  // console.log('analytics: ', data)
  // if (data.eventAction !== "VIEWED_ONBOARD") {
  //   // await this.$ga.event({
  //   //   eventCategory: data.eventCategory,
  //   //   eventAction: data.eventAction,
  //   //   eventLabel: data.eventLabel,
  //   //   value: parseInt(data.value || 0)
  //   // });
  //   window.gtag('event', data.eventAction, {
  //     eventCategory: data.eventCategory,
  //     eventAction: data.eventAction,
  //     eventLabel: data.eventLabel,
  //     value: parseInt(data.value || 0),
  //     utm_source: data.utm_source || '',
  //     utm_medium: data.utm_medium || '',
  //     utm_campaign: data.utm_campaign || '',
  //     payment_type: data.payment_type || ''
  //   })
  // }

  // eslint-disable-next-line
  amplitude.getInstance().logEvent(data.eventAction, data);
  // let value = data.value
  // if(!Number(data.value)) {
  //   value = 0
  // }

  //Facebook Pixel
  // window.fbq("trackCustom", data.eventAction, {
  //   currency: "BRL",
  //   value: parseFloat(value || 0)
  // });
  // if (data.eventAction === "CREATE-NEW-LEAD") {
  //   window.fbq("track", "Subscribe", {
  //     currency: "BRL",
  //     value: parseFloat(data.value || 0),
  //     predicted_ltv: parseFloat(data.value * 24 || 0)
  //   });
  // } else if (data.eventAction === "SIGN-CONTRACT") {
  //   window.fbq("track", "Purchase", {
  //     currency: "BRL",
  //     value: parseFloat(data.value || 0)
  //   });
  // } else if (data.eventAction === "CREATE-NEW-USER") {
  //   window.fbq("track", "Subscribe", {
  //     currency: "BRL",
  //     value: parseFloat(data.value || 0),
  //     predicted_ltv: parseFloat(data.value * 24 || 0)
  //   });
  // } else if (data.eventAction === "CREATE-NEW-CARD") {
  //   window.fbq("track", "AddPaymentInfo", {
  //     currency: "BRL",
  //     value: parseFloat(data.value * 2 || 0)
  //   });
  // }

  if(data.boleto) {
    data.eventCategory = "onboard"
    data.eventAction = "ACCEPT_BOLETO",
    data.eventLabel = "SELECTION_BOLETO_ONBOARDING",
    // window.gtag('event', data.eventAction, {
    //   eventCategory: data.eventCategory,
    //   eventAction: data.eventAction,
    //   eventLabel: data.eventLabel,
    //   value: parseInt(data.value || 0),
    //   utm_source: data.utm_source || '',
    //   utm_medium: data.utm_medium || '',
    //   utm_campaign: data.utm_campaign || '',
    // })
    amplitude.getInstance().logEvent(data.eventAction, data);
  }


  if(data.referral_code) {
    data.eventCategory = data.referral_code.category
    data.eventAction = data.referral_code.action
    data.eventLabel = data.referral_code.action,
    data.value = data.referral_code.value,
    data.indicated = data.referral_code.indicated,
    data.indicator = data.referral_code.indicator,
    // window.gtag('event', data.eventAction, {
    //   eventCategory: data.eventCategory,
    //   eventAction: data.eventAction,
    //   eventLabel: data.eventLabel,
    //   value: parseInt(data.value || 0),
    //   indicated: data.indicated,
    //   indicator: data.indicator,
    // })
    amplitude.getInstance().logEvent(data.eventAction, data);
  }
}

export const saveGTM = (data) => {
  if (this.$gtm.enabled()) {
    console.log(data)
    // window.dataLayer?.push({
    //   event: data.event || "assinar",
    //   target: data.category,
    //   // action: data.action,
    //   // "target-properties": data.label,
    //   value: data.value,
    //   // "interaction-type": data.noninteraction,
    //   // further parameters
    // });
  }
}