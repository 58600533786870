import gql from 'graphql-tag';
// import {filter} from 'graphql-anywhere';

// Query
export const GET_USER_BYEMAIL = gql`
    query userByEmail($email: String!) {
        userByEmail(email: $email) {
            customer_id
            name
            email
            referral_indicator
            referral_balance
            referral_balance_future
            status
            group
        }
    }
`;

// Mutations
export const STORE_USER = gql`
    mutation storeUser(
            $email: String!
            $name: String!
            $cpf: String
            $phone: String
            $group: String!
            $ID_document: String
            $status: StatusEnum!
            $payment_type: String
            $profession: String
            $marital_status: String
            $marital_type: String
            $gender: String
            $date_of_birth: Date
            $password: String
            $card_bonus: Int
            $average_consumption: String
            $boleto_accepted_by: String,
            $boleto_accepted_at: Date,
            $boleto_ipinfo: InputIPInfo,
            $signedcontract_at: Date
            $signedcontract_ipinfo: InputIPInfo
            $installation_number: String
            $signedcontract_by: String
            $signedcontract_source: String
            $signedcontract_partner: String
        ) {
        	storeUser(
            user: {
                email: $email
                name: $name
                cpf: $cpf
                phone: $phone
                group: $group
                ID_document: $ID_document
                status: $status
                payment_type: $payment_type
                profession: $profession
                marital_status: $marital_status
                marital_type: $marital_type
                gender: $gender
                date_of_birth: $date_of_birth
                password: $password
                card_bonus: $card_bonus
                average_consumption: $average_consumption
                boleto_accepted_by: $boleto_accepted_by,
                boleto_accepted_at: $boleto_accepted_at,
                boleto_ipinfo: $boleto_ipinfo,
                signedcontract_at: $signedcontract_at
                signedcontract_ipinfo: $signedcontract_ipinfo
                installation_number: $installation_number
                signedcontract_by: $signedcontract_by
                signedcontract_source: $signedcontract_source
                signedcontract_partner: $signedcontract_partner
            }
        ) {
            id
            buyer_id
            customer_id
        }
    }
`;


export const UPDATE_USER = gql`
    mutation updateUser(
            $id: ID!
            $referral_balance_future: Int
            $referral_indicator: Int
            $name: String!
            $email: String!
            $status: StatusEnum!
            $group: String!
        ) {
        	updateUser(
            user: {
                id: $id
                name: $name
                email: $email
                referral_balance_future: $referral_balance_future
                referral_indicator: $referral_indicator
                status: $status
                group: $group
            }
        ) {
            id
        }
    }
`;